import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'

const fadeInAnimation = keyframes`${fadeIn}`

const Content = styled.div`
  animation: 0.75s ${fadeInAnimation};
  background: rgba(255, 255, 255, 0.6);
  position: relative;
  width: 100%;
  height: 200%;
  top: -100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  @media(max-width: 801px){
    margin-top: 110px;
    margin-bottom: 50px;
  }
  .mobile {
    display: none;
  }
  .logoContainer {
    width: calc(100% - 40px);
    max-width: 800px;
  }
  .icon-container {
    width: 15%;
    margin-left: 5%;
    @media(max-width: 801px){
      width: 60%;
      margin: auto;
    }
    img {
      width: 100%;
    }
    &.right {
      margin-left: 0;
      margin-right: 5%;
    }
  }
  .text {
    width: calc(100% - 40px);
    max-width: 1170px;
    h1 {
      font-size: calc(2rem + 2vw);
      font-weight: 400;
      text-shadow: 1px 1px 2px #555;
      margin: 0;
    }
    .secondary & {
      text-align: left;
      width: 60%;
      @media(max-width: 801px){
        width: 92%;
      }
    }
  }
  @media (max-width: 801px) {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
`

export default Content
