import React, { Fragment } from 'react'
import Layout from '../components/layout'
import { Tween, Timeline, SplitLetters } from 'react-gsap'
import { Controller, Scene } from 'react-scrollmagic'
import { Parallax } from 'react-parallax'
import SEO from '../components/seo'
import Section from '../components/styles/section'
import Content from '../components/styles/hpContent'
import homepageBG1 from '../images/homepageBG1.jpg'
import homepageBG2 from '../images/homepageBG2.jpg'
import homepageBG3 from '../images/homepageBG3.jpg'
import homepageBG4 from '../images/homepageBG4.jpg'
import msdLogo from '../images/msdLogo.png'
import laptop from '../images/svg/laptop.svg'
import wordpress from '../images/svg/wordpress.svg'
import TopBar from '../components/styles/topBar'

const IndexPage = props => (
  <Layout>
    <SEO title="Home" />
    <Parallax bgImage={homepageBG1} strength={400}>
      <Section>
        <Content>
          <div className="logoContainer">
            <img src={msdLogo} alt="MSD Web Solutions Logo" />
          </div>
          <Timeline
            wrapper={<div className="text desktop"></div>}
            target={
              <Fragment>
                <SplitLetters>
                  <h1 style={{ display: 'inline-block' }}>Quality&nbsp; Sites,&nbsp; Affordable&nbsp; Prices</h1>
                </SplitLetters>
              </Fragment>
            }
          >
            <Tween staggerFrom={{ opacity: 0 }} staggerTo={{ opacity: 1 }} stagger={0.03} position="+=1" />
          </Timeline>
          <div className="text mobile">
            <h1>Quality Sites, Affordable Prices</h1>
          </div>
        </Content>
      </Section>
    </Parallax>
    <Parallax bgImage={homepageBG2} strength={400}>
      <Section className="parallaxParent secondary" id="yourWeb">
        <TopBar />
        <Content>
          <div className="text">
            <h1>Your website, your way!</h1>
            <p>
              Your website is important, it is the first impression many people will have of your company, so we want to
              help you put your best foot forward. We will work with you to design and develop a website that
              effectively represents your business or organization and enhances your branding.
            </p>
          </div>
          <Controller>
            <Scene triggerElement="#yourWeb" duration={1000}>
              {progress => (
                <Tween
                  from={{
                    scale: '0',
                    opacity: '0.2',
                    ease: 'Power4.easeOut',
                  }}
                  to={{
                    scale: '1',
                    opacity: '1',
                    ease: 'Power4.easeOut',
                  }}
                  totalProgress={progress}
                  paused
                >
                  <div className="icon-container laptop">
                    <img src={laptop} alt="Laptop" />
                  </div>
                </Tween>
              )}
            </Scene>
          </Controller>
        </Content>
      </Section>
    </Parallax>
    <Parallax bgImage={homepageBG3} strength={400}>
      <Section className="parallaxParent secondary" id="wordpress">
        <TopBar />
        <Content>
          <Controller>
            <Scene triggerElement="#wordpress" duration={1000}>
              {progress => (
                <Tween
                  from={{
                    scale: '0',
                    opacity: '0.2',
                    ease: 'Power4.easeOut',
                  }}
                  to={{
                    scale: '1',
                    opacity: '1',
                    ease: 'Power4.easeOut',
                  }}
                  totalProgress={progress}
                  paused
                >
                  <div className="icon-container wordpress right">
                    <img src={wordpress} alt="WordPress Logo" />
                  </div>
                </Tween>
              )}
            </Scene>
          </Controller>
          <div className="text">
            <h1>Headless WordPress</h1>
            <p>
              The world of WordPress has come a long way. We are now able to create a decoupled or headless WordPress
              site which enables you to continue to use WordPress for all of your backend needs, but we use modern tools
              such as react.js to build a powerful and fast frontend. What does this mean for you? Quite simply, we can
              access the good parts of WordPress and still take advantage of new user interface tools, creating a better
              experience for you and your users.
            </p>
          </div>
        </Content>
      </Section>
    </Parallax>
    <Parallax bgImage={homepageBG4} strength={400}>
      <Section id="commitment" className="commitment">
        <TopBar />
        <Content>
          <div className="text">
            <h1>Committed to Customer Service!</h1>
            <p>
              We are a small company that is committed to ensuring you, the customer, get the time and consideration you
              deserve. You will have the personal attention that you need in order to get the site that you desire.
              Every site is carefully constructed to effectively convey your message, attract attention, and provide you
              with new customers. This means a blend of form and function. Your site will be easy for your customers to
              navigate as well as beautifully designed and SEO friendly. Contact us today and we can get started.
            </p>
          </div>
        </Content>
      </Section>
    </Parallax>
  </Layout>
)

export default IndexPage
