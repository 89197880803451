import styled from 'styled-components'

const TopBar = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: ${props => props.bgColor || '#e06a03'};
  :after {
    content: '';
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-top: 50px solid ${props => props.bgColor || '#e06a03'};
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -50px;
  }
`

export default TopBar
